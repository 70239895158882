.table-image{
    width: 50px;
    object-fit: contain;
    box-shadow: 0px 0px 2px 1px #71707880;
    border-radius: 3px;
    cursor: pointer;
}

.display-show{
    display: block;
}
.display-hidden{
    display: none;
}
.upload-image{
    width: 100%;
    max-width: 150px;
    height: 150px;
    object-fit: fill;
    // box-shadow: 0px 0px 2px 1px #10e3bc;
    border-radius: 4px;
}

.modal-image{
    width: 100%;
    object-fit: scale-down;
    padding: 10px;
}

.fontIcon{
    cursor: pointer;
}

.loader-modal .modal-content{
    background: #ff000000 !important;
    border: 0 !important;
}
.userview-heading{
    font-size: 17px;
    padding-left: 6px;
}

.userview-text{
    font-size:14px;
    background: #ffffff;
    padding: 6px;
    border-radius: 3px;
    border: 1px solid #cfd4d8;
}

.form-design{
    background: #3c4b63;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #00e8ff;
}
.outlineModal .modal-dialog .modal-content{
    background: #3c4b63;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #00e8ff;
}

.label-text{
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
}
.list-heading-text{
    color: #6ccfd9cc;
    font-weight: 600;
}

.text-editor{
    min-height: 160px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    min-height: 143px;
}
.ck.ck-editor__main>.ck-editor__editable{
    min-height: 143px;
}

.outline-card{
    border: 1px solid #86ff00;
    border-radius: 6px;
    padding: 20px;
}

//description editor
.rdw-editor-main{
    background: #ffffff;
}

.rdw-editor-toolbar{
margin-bottom: 0 !important;
}

.imagedeleteicon{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 21px;
    right: 9px;
    color: #000000;
}

.taskList{
    padding: 10px;
    box-shadow: 0px 0px 2px 1px #bfbdbd;
    border-radius: 3px;
}

.tasklisttext{
    color: #bfbdbd;
    font-size: 12px;
}
.tasklisttext p{
    color: #bfbdbd;
   background:#ff000000;
}
.tasklisttext span{  
    color: #bfbdbd;  
   background:#ff000000;
}
.tasklisNumber{
    background: #636f83;
    color: #ffffff;
    padding: 3px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 9%;
    left: 2%;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 22px;
    padding-top: 5px;
}
.tasklisDelete{
    background: #636f83;
    color: #ffffff;
    padding: 3px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 9%;
    right: -12px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 22px;
    padding-top: 5px;
    cursor: pointer;
}
.outlineNumber{
    background: #636f83;
    color: #ffffff;
    padding: 3px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 9%;
    left:2px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 22px;
    padding-top: 5px;
}
.outlineDelete{
    background: #636f83;
    color: #ffffff;
    padding: 3px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 9%;
    right: -29px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 22px;
    padding-top: 5px;
    cursor: pointer;
}

.outlineList{
    border: 1px solid #647c48;
    border-radius: 6px;
    padding: 20px;
}

.text-muted{
    color: red !important;
}

td{
    max-width: 200px;
}